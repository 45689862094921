

































import { Component, Mixins, Vue } from 'vue-property-decorator'
import LanguageMenu from '@/general/components/layouts/LanguageMenu.vue'
@Component({
  components: { LanguageMenu }
})
export default class SettingsMenu extends Vue {
  getUrl(): string {
    return window.location.origin
  }
}
