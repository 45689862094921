





































import {Component, Mixins, Watch} from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService'
import LanguageMenu from '@/general/components/layouts/LanguageMenu.vue'
import MenuIcon from '@/general/components/icons/menu.vue'
import {
  GetECMRSByPlanning_GetECMRSByPlanning_items,
  GetECMRSByPlanning_GetECMRSByPlanning_items_value
} from '@/generated/GetECMRSByPlanning';

class Item {
  text: string
  icon: string
  path: object

  constructor(text: string, icon: any, path: object) {
    this.text = text
    this.icon = icon
    this.path = path
  }
}

@Component({
  components: {
    LanguageMenu,
    MenuIcon
  }
})
export default class BottomNav extends Mixins(AuthenticationService) {
  activeButton: string = ''
  hasECMRS = Array.from((this.$store.state.transport.ecmrs.values() as GetECMRSByPlanning_GetECMRSByPlanning_items_value[][])).filter((value: GetECMRSByPlanning_GetECMRSByPlanning_items_value[]) => value.length).length

  @Watch('$store.state.transport.newEcmrs')
  setHasECMRS() {
    this.hasECMRS = Array.from((this.$store.state.transport.ecmrs.values() as GetECMRSByPlanning_GetECMRSByPlanning_items_value[][])).filter((value: GetECMRSByPlanning_GetECMRSByPlanning_items_value[]) => value.length).length
    this.$store.state.transport.newEcmrs = false
  }

  get onConsignment() {
    return this.$store.state.onConsignment
  }

  get items() {
    return [
      new Item('scan.qrCode', 'qr_code_2', this.getUrl('scan-qrcode')),
      new Item('common.attachments', 'attachment', this.getUrl('scan-documents')),
      new Item('shipment.single', 'local_shipping', this.getUrl('scan-info'))
    ]
  }

  goToScanner() {
    this.$store.dispatch('shipment/resetScan')
    this.$router.push({ name: 'scan', query: { ...this.$route.query } })
  }

  getUrl(name: String) {
      return {
        name,
        params: { shipment: this.$route.params.shipment, consignment: this.$route.params.consignment },
        query: { ...this.$route.query }
      }
  }
}
