
























import {Component, Mixins, Watch} from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService'
import TopNavMobile from '@/general/components/layouts/TopNavMobile.vue'
import BottomNav from '@/general/components/layouts/BottomNav.vue'
import RouterViewWithError from '@/general/components/layouts/RouterViewWithError.vue'
import FirstLogin from '@/general/components/layouts/FirstLogin.vue'
import Popup from "@/general/components/common/Popup.vue";
import PlanningService from "@/modules/planning/PlanningService";
import {findAllPlanningUpdatedMessages} from "@/general/services/DatabaseService";
import { ListSelfPlannings_ListSelfPlannings_items } from '@/generated/ListSelfPlannings'
import {GetPlanning_GetPlanning, GetPlanning_GetPlanning_consignments} from '@/generated/GetPlanning'
import {StatusIDCreateModel, StatusState, StatusType} from "@/generated/globalTypes";
import {PlanningUtil} from "@/modules/planning/util/PlanningUtil";
import StatusService from "@/modules/shipments/services/StatusService";

@Component({
  components: {
    Popup,
    TopNavMobile,
    BottomNav,
    RouterViewWithError,
    FirstLogin
  }
})
export default class Mobile extends Mixins(AuthenticationService, PlanningService, PlanningUtil, StatusService) {
  show = true

  planningOld: ListSelfPlannings_ListSelfPlannings_items | null = null
  planningNew: GetPlanning_GetPlanning | null = null
  isUpdate = false

  popupHeader() {
    const base = (this.planningNew?.name || this.planningOld?.name) + ' - ' + this.$t('common.trip')
    if (this.planningOld || this.isUpdate) {
      if (this.planningNew) {
        return base + ' updated'
      } else {
        return base + ' removed'
      }
    } else {
      return base + ' created'
    }
  }

  emitUpdateAcceptedStatus() {
      const statuses: StatusIDCreateModel[] = this.createupdateAccapted(this.planningNew || this.planningOld!!)
      if (statuses.length) {
        this.createStatus1({
          statusCreateModels: statuses,
          documents: []
        })
    }
  }

  private createupdateAccapted(planning: GetPlanning_GetPlanning): StatusIDCreateModel[] {
    return planning.stops.flatMap(stop => {
      return stop.activities.filter(activity => activity.consignment).map(activity => {
        let status = this.createBundledStatusCreateModel(null, null, null, {arrival: null, start: new Date().getTime(), finish: null}, null, StatusType.UPDATE_ACCEPTED, { tripIdentifier: this.planningNew?.identifier!!, activityIdentifier: activity.identifier!!, stopIdentifier: stop.identifier!! }, null, null, null, [], StatusState.ACCEPTED)
        let consignment = planning.consignments.find(consignment => consignment.id === activity.consignment)!!
        return {
          consignment: consignment.name,
          shipment: consignment.shipment?.identifier!!,
          expeditor: consignment.shipment?.expeditor.id!!,
          status: status
        }
      })
    })
  }

  popupChangelog(): string[] {
    if (!this.planningOld || !this.planningNew) return []
    let changelog: string[] = []

    const addedStops = this.planningNew.stops.filter(s => !this.planningOld!.stops.some(ns => ns.id === s.id))
    const removedStops = this.planningOld.stops.filter(s => !this.planningNew!.stops.some(ns => ns.id === s.id))

    if (addedStops.length) {
      changelog.push(`${addedStops.length === 1 ? 'Stop' : 'Stops'} added: ${addedStops.map(s => s.address.name).join(', ')}`)
    }
    if (removedStops.length) {
      changelog.push(`${removedStops.length === 1 ? 'Stop' : 'Stops'} removed: ${removedStops.map(s => s.address.name).join(', ')}`)
    }

    const oldStops = this.planningOld.stops.filter(s => this.planningNew!.stops.some(ns => ns.id === s.id))
    const updatedStops = this.planningNew.stops.filter(s => this.planningOld!.stops.some(ns => ns.id === s.id))

    for (let i = 0; i < oldStops.length; i++) {
      const oldStop = oldStops[i]
      const updatedStop = updatedStops[i]

      const addedActivities = updatedStop.activities.filter(s => !oldStop.activities.some(ns => ns.id === s.id))
      const removedActivities = oldStop.activities.filter(s => !updatedStop.activities.some(ns => ns.id === s.id))

      if (addedActivities.length) {
        changelog.push(`${updatedStop.address.name}: ${addedActivities.length} ${addedActivities.length === 1 ? 'activity' : 'activities'} added.`)
      }
      if (removedActivities.length) {
        changelog.push(`${updatedStop.address.name}: ${removedActivities.length} ${removedActivities.length === 1 ? 'activity' : 'activities'} removed.`)
      }
    }

    return changelog
  }

  closePopup() {
    try {
      // Uitgezet ivm email Bert 03-02-2023
      this.emitUpdateAcceptedStatus()
    } catch (e) {
      this.$store.commit('snackbar/show', { color: 'error', text: 'Error sending confirmation to backend: ' + e })
    } finally {
      this.cleanUpdate()
    }
  }

  cleanUpdate() {
    this.planningOld = null
    this.planningNew = null
    this.isUpdate = false
  }

  @Watch('$store.state.transport.planningUpdated')
  updatePlanningFromUpdateNotification() {
    const id = this.$store.state.transport.planningUpdated
    const currentPlanning = this.$store.state.transport.planning
    if (id) {
      this.cleanUpdate()
      this.getPlanningByIdOrAccessCode(id).then(result => {
        if (currentPlanning && id === currentPlanning.id) {
          this.$store.dispatch('transport/setPlanning', result)
          if (!result || result.stops.length === 0) {
            this.planningOld = currentPlanning
            this.planningDeleted()
          } else {
            this.planningOld = currentPlanning
            this.planningNew = result
          }
        } else {
          this.isUpdate = new Date(result.updated).getTime() - new Date(result.created).getTime() > 1000
          if (result && result.stops.length > 0) {
            this.planningNew = result
          } else {
            // @ts-ignore
            this.planningOld = result
          }
        }
      }).catch(() => {
        if (currentPlanning && id === currentPlanning.id) {
          this.planningOld = currentPlanning
          this.planningDeleted()
        }
      }).finally(() => {
        this.completeUpdate()
      })
    }
  }

  completeUpdate() {
    if (this.$route.name === 'all-plannings') {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
    this.$store.commit('transport/setPlanningUpdated', null)
  }

  planningDeleted() {
    this.$router.replace({ name: 'all-plannings', query: { ...this.$route.query } })
    this.$store.dispatch('transport/setPlanning', null)
  }

  created() {
    if (this.$route.query.accessCode) {
      this.authenticateWithAccessToken()
    } else {
      this.checkLogin(false, true)
    }
    this.$store.dispatch('scan/init')

    findAllPlanningUpdatedMessages().then((result: string[] | undefined) => {
      if (result && result.length) {
          this.$store.dispatch('transport/planningUpdatedFromWebsocket', { id: result[result.length - 1], email: this.$store.state.user?.email, currentUser: this.$store.state.user?.email })
      }
    })

  }

  get planningUpdatedPopupSetting(): boolean {
    // This setting can be set to true in the custom.js file if the popup when a planning is updated by ws is desired
    // @ts-ignore
    const usePopup = window.usePlanningUpdatedPopup ? window.usePlanningUpdatedPopup : false
    console.log('Planning update popup enabled: ' + usePopup)
    return usePopup
  }
}
