






























import { Component, Mixins, Vue } from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService'
import SurePopup from "@/general/components/common/SurePopup.vue";
@Component({
  components: {SurePopup}
})
export default class UserMenu extends Mixins(AuthenticationService) {
  surePopup = false
}
