





























import { Component, Vue, Watch } from 'vue-property-decorator'
import UserMenu from '@/general/components/layouts/UserMenu.vue'
import SettingsMenu from '@/general/components/layouts/SettingsMenu.vue'
@Component({
  components: { SettingsMenu, UserMenu }
})
export default class TopNavMobile extends Vue {
  online: boolean = true
  sheet = false

  @Watch('$store.state.onConsignment')
  @Watch('$store.state.transport.consignment')
  setTitle() {
    if (this.$store.state.onConsignment && this.$store.state.transport.consignment && this.$store.state.transport.consignment.name) {
      this.$store.state.mobileTitle = {
        title: this.$store.state.transport.consignment.name,
        subtitle: undefined,
        extendedTitle: undefined
      }
    }
  }

  created() {
    window.addEventListener('online', this.setOnline)
    window.addEventListener('offline', this.setOnline)
  }

  setOnline() {
    this.online = window.navigator.onLine
  }

  usesAccessCode(): boolean {
    return !!this.$route.query.accessCode
  }
}
